import React, { Component } from 'react'
import Layout from '../../components/layout';
import { navigate } from '@reach/router'
import { isLoggedIn } from '../../services/auth';
import { Container, Nav, NavItem, NavLink } from 'reactstrap'
import { Banner } from '../../components/banner';

export default class netsClick extends Component {
    constructor(props) {
        super(props);

        if (typeof window !== "undefined") {
            window.$ = window.jQuery = require('jquery');
        }

        this.state = {
        }
        if (!isLoggedIn()) {
            navigate('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
            if (typeof window !== "undefined") {
                window.location.reload()
            }
        }
    }
    render() {
        return (
            <Layout>
                <Banner title={`eNETS/NETS QR`} />
                <Container
                    style={{
                        padding: '2rem 2rem 1rem',
                        minHeight: '75vh'
                    }}
                >
                    <div style={{ marginLeft: "45px", marginBottom: "20px" }}>Click on below to start your integration with us.</div>
                    <div>
                        <Nav vertical tabs={true}>
                            <NavItem>
                                <NavLink href="/docs/guide">
                                    API Guide
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="/docs/reference">
                                    API Reference
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="/docs/resources">
                                    Resources
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                </Container>
            </Layout>
        )
    }
}